.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: sans-serif;
  max-width: 600px; /* Added max-width for better responsiveness */
  margin: 0 auto;   /* Center the container horizontally */
}

.input-textarea, .output-textarea {
  width: 100%; /* Use full width of the container */
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical; /* Allow vertical resizing */
}

.input-textarea {
  height: 150px; /* Adjust as needed */
}

.output-textarea {
  height: 150px; /* Adjust as needed */
}

.anonymize-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: auto; /* Let the button size itself to its content */
  align-self: flex-start; /* Align button to the start (left) */
}

.anonymize-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 5px; /* Add some space above the error message */
  width: 100%; /* Use full width of the container */
  text-align: left; /* Align text to the left */
}